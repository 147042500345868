/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Recon Backend API
 * API for the Recon Backend service
 * OpenAPI spec version: 0.2.17
 */
import { useQuery } from "@tanstack/vue-query";
import type {
  DataTag,
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryReturnType,
} from "@tanstack/vue-query";
import { unref } from "vue";
import type { UserResponse } from "../reconBackendAPI.schemas";
import { customFetch } from "../../utils/customFetch";
import { mutator } from "../../utils/customQueryOptions";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Profile
 */
export type profileResponse = {
  data: UserResponse;
  status: number;
  headers: Headers;
};

export const getProfileUrl = () => {
  return `http://localhost:5001/v1/users/profile`;
};

export const profile = async (
  options?: RequestInit,
): Promise<profileResponse> => {
  return customFetch<Promise<profileResponse>>(getProfileUrl(), {
    ...options,
    method: "GET",
  });
};

export const getProfileQueryKey = () => {
  return ["http:", "localhost:5001", "v1", "users", "profile"] as const;
};

export const useProfileQueryOptions = <
  TData = Awaited<ReturnType<typeof profile>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getProfileQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof profile>>> = () =>
    profile(requestOptions);

  const customOptions = mutator({ ...queryOptions, queryKey, queryFn });

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof profile>>,
    TError,
    TData
  >;
};

export type ProfileQueryResult = NonNullable<
  Awaited<ReturnType<typeof profile>>
>;
export type ProfileQueryError = unknown;

/**
 * @summary Profile
 */

export function useProfile<
  TData = Awaited<ReturnType<typeof profile>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof profile>>, TError, TData>
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = useProfileQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}
