<template>
  <Popover v-model:open="isOpen">
    <PopoverTrigger asChild>
      <Button variant="ghost" class="flex items-center gap-2">
        Feedback
      </Button>
    </PopoverTrigger>

    <PopoverContent class="w-80 p-4">
      <div class="space-y-4">
        <Textarea
          v-model="feedbackText"
          placeholder="Comments? Suggestions? Bugs? Submit them here!"
          :rows="4"
        />
        <div class="flex items-center space-x-2">
          <Checkbox 
            id="screenshot" 
            v-model:checked="includeScreenshot"
          />
          <label 
            for="screenshot" 
            class="text-sm text-gray-700 cursor-pointer"
          >
            Include screenshot
          </label>
        </div>
        <div class="flex justify-end space-x-2">
          <Button
            variant="ghost"
            @click="isOpen = false"
          >
            Cancel
          </Button>
          <Button
            :disabled="isPending"
            @click="handleSubmit"
          >
            {{ isPending ? 'Submitting...' : 'Submit' }}
          </Button>
        </div>
      </div>
    </PopoverContent>
  </Popover>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import html2canvas from 'html2canvas'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { Textarea } from '@/components/ui/textarea'
import { useSubmitFeedback } from '@/api/feedback/feedback'
import { submitFeedbackBody } from '@/api/feedback/feedback.zod'
import { useToast } from '@/components/ui/toast/use-toast'

const feedbackText = ref('')
const isOpen = ref(false)
const includeScreenshot = ref(false)
const { toast } = useToast()
const route = useRoute()

const { mutate: submitFeedback, isPending } = useSubmitFeedback({
  mutation: {
    onSuccess: () => {
      toast({
        title: 'Feedback Submitted',
        description: 'Thank you for your feedback!',
      })
      feedbackText.value = ''
      includeScreenshot.value = false
      isOpen.value = false
    },
    onError: (error) => {
      toast({
        title: 'Error',
        description: 'Failed to submit feedback. Please try again.',
        variant: 'destructive',
      })
    },
  },
})

const handleSubmit = async () => {
  let screenshot_base64 = undefined

  if (includeScreenshot.value) {
    try {
      const canvas = await html2canvas(document.body)
      screenshot_base64 = canvas.toDataURL('image/png').split(',')[1]
    } catch (error) {
      console.error('Failed to capture screenshot:', error)
    }
  }

  const parsedData = submitFeedbackBody.safeParse({
    text: feedbackText.value,
    metadata: {
      path: route.fullPath,
      userAgent: navigator.userAgent,
    },
    screenshot_base64,
  })

  if (!parsedData.success) {
    toast({
      title: 'Invalid Input',
      description: 'Please enter some feedback text.',
      variant: 'destructive',
    })
    return
  }

  submitFeedback({ data: parsedData.data })
}
</script>
