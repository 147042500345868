<template>
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <Button variant="ghost" class="flex items-center gap-2">
        <span v-if="!isLoading">
          <UserIcon class="h-5 w-5" />
        </span>
        <span v-else>
          <Loader2Icon class="h-5 w-5 animate-spin" />
        </span>
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end" side="bottom" :avoidCollisions="true">
      <DropdownMenuItem @click="router.push('/profile')">
        Profile
      </DropdownMenuItem>
      <DropdownMenuItem v-if="isAdmin" @click="router.push('/admin')">
        Admin
      </DropdownMenuItem>
      <DropdownMenuSeparator />
      <LogoutButton />
    </DropdownMenuContent>
  </DropdownMenu>
</template>

<script setup lang="ts">
import { Loader2Icon, UserIcon } from 'lucide-vue-next';
import { Button } from '@/components/ui/button';
import { useRouter } from 'vue-router';
import { 
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator
} from '@/components/ui/dropdown-menu';
import LogoutButton from './LogoutButton.vue';
import { useUser } from '@/services/user';

const router = useRouter();
const { isAdmin, isLoading } = useUser();
</script>
