import { useAuth0 } from '@auth0/auth0-vue';
import { computed } from 'vue';
import { useProfile } from '@/api/users/users';
import type { z } from 'zod';
import type { profileResponse } from '@/api/users/users.zod';

type Profile = z.infer<typeof profileResponse>;

export function useUser() {
    const { user, isAuthenticated, isLoading: auth0Loading } = useAuth0();
    const { data: userProfile, isLoading: profileLoading } = useProfile();

    const isLoading = computed(() => auth0Loading.value || profileLoading.value);

    const isAdmin = computed(() => {
        return user.value?.['n0tlikethat.dev/roles']?.includes('admin') || userProfile.value?.data.admin;
    });

    const userObject = computed(() => {
        return user.value
    });

    const userProfileObject = computed(() => {
        return userProfile.value?.data
    });

    return {
        user: userObject,
        userProfile: userProfileObject,
        isAuthenticated,
        isLoading,
        isAdmin
    };
}
