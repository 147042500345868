/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Recon Backend API
 * API for the Recon Backend service
 * OpenAPI spec version: 0.2.17
 */
import { useMutation } from "@tanstack/vue-query";
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationReturnType,
} from "@tanstack/vue-query";
import type {
  FeedbackSubmission,
  HTTPValidationError,
} from "../reconBackendAPI.schemas";
import { customFetch } from "../../utils/customFetch";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Submit Feedback
 */
export type submitFeedbackResponse = {
  data: unknown;
  status: number;
  headers: Headers;
};

export const getSubmitFeedbackUrl = () => {
  return `http://localhost:5001/v1/feedback/feedback`;
};

export const submitFeedback = async (
  feedbackSubmission: FeedbackSubmission,
  options?: RequestInit,
): Promise<submitFeedbackResponse> => {
  return customFetch<Promise<submitFeedbackResponse>>(getSubmitFeedbackUrl(), {
    ...options,
    method: "POST",
    headers: { "Content-Type": "application/json", ...options?.headers },
    body: JSON.stringify(feedbackSubmission),
  });
};

export const getSubmitFeedbackMutationOptions = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitFeedback>>,
    TError,
    { data: FeedbackSubmission },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof submitFeedback>>,
  TError,
  { data: FeedbackSubmission },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof submitFeedback>>,
    { data: FeedbackSubmission }
  > = (props) => {
    const { data } = props ?? {};

    return submitFeedback(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SubmitFeedbackMutationResult = NonNullable<
  Awaited<ReturnType<typeof submitFeedback>>
>;
export type SubmitFeedbackMutationBody = FeedbackSubmission;
export type SubmitFeedbackMutationError = HTTPValidationError;

/**
 * @summary Submit Feedback
 */
export const useSubmitFeedback = <
  TError = HTTPValidationError,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitFeedback>>,
    TError,
    { data: FeedbackSubmission },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationReturnType<
  Awaited<ReturnType<typeof submitFeedback>>,
  TError,
  { data: FeedbackSubmission },
  TContext
> => {
  const mutationOptions = getSubmitFeedbackMutationOptions(options);

  return useMutation(mutationOptions);
};
