import type { Ref } from "vue";
import type { 
  QueryFunction, 
  QueryKey
} from "@tanstack/vue-query";

// Update interface to be more flexible with function types
interface QueryOptions<TData = any> {
  queryKey: QueryKey | (string | Ref<string>)[];
  queryFn: QueryFunction<TData>;
}

// Helper function to extract cache-relevant values from any object
const extractCacheValues = (obj: any, prefix: string = ''): any[] => {
  if (!obj || typeof obj !== 'object') {
    return [String(obj)];
  }

  // Skip null/undefined
  if (obj === null) return [];

  // Handle arrays
  if (Array.isArray(obj)) {
    return obj.flatMap(item => extractCacheValues(item));
  }

  // Handle Date objects
  if (obj instanceof Date) {
    return [obj.toISOString()];
  }

  // If it's a ref, return it directly to maintain reactivity
  if ('value' in obj && obj.value !== undefined) {
    return [obj];
  }

  const values: any[] = [];

  // Extract meaningful values from object
  for (const [key, value] of Object.entries(obj)) {
    // Skip null/undefined values
    if (value == null) {
      continue;
    }

    // Handle nested objects
    if (typeof value === 'object') {
      const nestedValues = extractCacheValues(value, `${prefix}${key}:`);
      values.push(...nestedValues);
    } else {
      // Handle primitive values
      if (value === true) {
        values.push(`${prefix}${key}`);
      } else if (value !== false) {
        values.push(`${prefix}${key}:${value}`);
      }
    }
  }

  return values;
};

export const mutator = <TData = any>(args: QueryOptions<TData>) => {
  const { queryKey } = args;
  
  // Clean and transform the key parts
  const transformedKey = [...queryKey]
    .filter((part, index) => {
      // Skip protocol and host parts for all requests
      if (index <= 1) return false;
      return true;
    })
    .flatMap(part => {
      // Handle objects (including refs)
      if (typeof part === 'object' && part !== null) {
        return extractCacheValues(part);
      }
      return [String(part)];
    });

  // Add necessary parts for cache invalidation
  if (transformedKey.includes('assets')) {
    if (transformedKey.includes('favorites_only:true')) {
      transformedKey.push('favorites-view');
    }
    if (transformedKey.includes('undismissed_only:true')) {
      transformedKey.push('undismissed-view');
    }
  }

  return {
    ...args,
    queryKey: transformedKey
  };
};

export default mutator;