import { createAuth0 } from '@auth0/auth0-vue';
import { BASE_URL, LOGIN_URL, API_URL, ENV } from '@/utils/debug';

const prod_client_url = 'dxkTAbehfHUO35HdQCIYqhMMW52mtY6I'
const dev_client_url = 'F9yZlnesRyoDKbX1X15gu0YCI5a4RAVP'

export const auth0 = createAuth0({
    domain: LOGIN_URL,
    clientId: ENV === 'prod' ? prod_client_url : dev_client_url,
    authorizationParams: {
      redirect_uri: BASE_URL + '/callback',
      audience: API_URL
    }
  })