import { auth0 } from "@/utils/auth0";
import { BASE_API } from "@/utils/debug";
import router from "@/router";

// NOTE: Supports cases where `content-type` is other than `json`
const getBody = <T>(c: Response | Request): Promise<T> => {
    const contentType = c.headers.get('content-type');
  
    if (contentType && contentType.includes('application/json')) {
      return c.json();
    }
  
    if (contentType && contentType.includes('application/pdf')) {
      return c.blob() as Promise<T>;
    }
  
    return c.text() as Promise<T>;
  };
  
  // NOTE: Update just base url
  const getUrl = (contextUrl: string): string => {
    const url = new URL(contextUrl);
    const pathname = url.pathname;
    const baseUrl = BASE_API;
  
    // Create new URLSearchParams and filter out null values
    const searchParams = new URLSearchParams(url.search);
    Array.from(searchParams.entries()).forEach(([key, value]) => {
      if (value === 'null' || value === null || value === '') {
        searchParams.delete(key);
      }
    });
  
    const cleanSearch = searchParams.toString();
    const searchString = cleanSearch ? `?${cleanSearch}` : '';
  
    const requestUrl = new URL(`${baseUrl}${pathname}${searchString}`);
  
    return requestUrl.toString();
  };
  
  // NOTE: Add headers
  const getHeaders = async (headers?: HeadersInit): Promise<HeadersInit> => {

    return {
      ...headers,
      Authorization: `Bearer ${await auth0.getAccessTokenSilently()}`
    };
  };
  
  export const customFetch = async <T>(
    url: string,
    options: RequestInit,
  ): Promise<T> => {
    const requestUrl = getUrl(url);
    const requestHeaders = getHeaders(options.headers);
  
    const requestInit: RequestInit = {
      ...options,
      headers: await requestHeaders,
    };
  
    const request = new Request(requestUrl, requestInit);
    const response = await fetch(request);

    if (response.status === 428) {
      const data = await response.json();
      if (data.detail.redirect) {
        router.push(data.detail.redirect);
        throw new Error('Redirecting to onboarding');
      }
    } else if (response.status === 402) {
      const data = await response.json();
      if (data.detail.redirect) {
        router.push(data.detail.redirect);
        throw new Error('Subscription required');
      }
    } else if (response.status === 401) {
      router.push('/unauthorized');
      throw new Error('Unauthorized');
    }

    if (!response.ok) {
      const errorData = await getBody<any>(response);
      const error = new Error('API Error');
      (error as any).response = {
        status: response.status,
        data: errorData
      };
      throw error;
    }

    const data = await getBody<T>(response);
  
    return { status: response.status, data } as T;
  };