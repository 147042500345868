<script setup lang="ts">
import { useGetUsers, useImpersonateUser, useStopImpersonating } from '@/api/admin/admin';
import { ref, computed } from 'vue';
import type { UserResponse } from '@/api/reconBackendAPI.schemas';
import { Button } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useUser } from '@/services/user';
import { useQueryClient } from '@tanstack/vue-query';

const queryClient = useQueryClient();
const isCollapsed = ref(false);
const isHidden = ref(false);

const { userProfile } = useUser();
const selectedUserId = ref<string>('');

const { data: users } = useGetUsers();
const impersonateUserMutation = useImpersonateUser();
const stopImpersonatingMutation = useStopImpersonating();

const isPending = computed(() => 
  Boolean(impersonateUserMutation.isPending.value || stopImpersonatingMutation.isPending.value)
);

const handleImpersonate = async () => {
  if (!selectedUserId.value) return;
  await impersonateUserMutation.mutateAsync({ userId: selectedUserId.value });
  queryClient.clear();
  window.location.reload();
};

const handleStopImpersonating = async () => {
  await stopImpersonatingMutation.mutateAsync();
  queryClient.clear();
  window.location.reload();
};

const toggleCollapse = () => {
  isCollapsed.value = !isCollapsed.value;
};

const toggleVisibility = () => {
  isHidden.value = !isHidden.value;
};
</script>

<template>
  <div v-show="!isHidden" class="fixed bottom-0 left-0 right-0 border-t bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
    <div class="container flex h-14 max-w-screen-2xl items-center justify-center relative">
      <div class="absolute right-4 flex gap-2">
        <Button
          variant="ghost"
          size="sm"
          @click="toggleCollapse"
        >
          {{ isCollapsed ? '↑' : '↓' }}
        </Button>
        <Button
          variant="ghost"
          size="sm"
          @click="toggleVisibility"
        >
          ×
        </Button>
      </div>
      
      <div v-show="!isCollapsed" class="flex items-center gap-4">
        <div v-if="userProfile?.impersonating">
            <p>Impersonating: {{ userProfile.impersonating }}</p>
        </div>
        <template v-else>
            <Select v-model="selectedUserId">
            <SelectTrigger class="w-[250px]">
                <SelectValue placeholder="Select a user to impersonate" />
            </SelectTrigger>
            <SelectContent>
                <SelectItem
                v-for="(user, index) in users?.data"
                :key="index"
                :value="user.user_id"
                >
                {{ user.email }}
                </SelectItem>
            </SelectContent>
            </Select>

            <Button
            @click="handleImpersonate"
            :disabled="!selectedUserId || isPending"
            variant="secondary"
            >
            Impersonate User
            </Button>
        </template>

        <Button
          v-if="userProfile?.impersonating"
          @click="handleStopImpersonating"
          :disabled="isPending"
          variant="destructive"
        >
          Stop Impersonating
        </Button>
      </div>
      
      <div v-show="isCollapsed" class="text-sm text-muted-foreground">
        {{ userProfile?.impersonating ? `Impersonating: ${userProfile.impersonating}` : 'Admin Controls' }}
      </div>
    </div>
  </div>
  <Button
    v-show="isHidden"
    class="fixed bottom-4 right-4"
    variant="secondary"
    size="sm"
    @click="toggleVisibility"
  >
    Show Admin Bar
  </Button>
</template>
