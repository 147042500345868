/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Recon Backend API
 * API for the Recon Backend service
 * OpenAPI spec version: 0.2.17
 */
import {
  z as zod
} from 'zod'
import { customFetch } from '../../utils/customFetch';

/**
 * @summary Submit Feedback
 */
export const submitFeedbackBody = zod.object({
  "text": zod.string(),
  "metadata": zod.object({

}),
  "screenshot_base64": zod.string().or(zod.null()).optional()
})

export const submitFeedbackResponse = zod.any()

